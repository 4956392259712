li {
  list-style: none;
}
.navbar {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
}
.nav-menu {
  display: flex;
  align-items: center;
}
.nav-branding {
  font-size: 2rem;
}
/* transition for links */
.nav-link {
  transition: 0.5s ease;
}
.nav-link:hover {
  color: dodgerblue;
}
.hamburger {
  display: none;
  cursor: pointer;
}
.bar {
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: white;
}
.nav-item {
  padding: 0 1.5rem;
}

@media (max-width: 760px) {
  .bar {
    display: block;
  }
  .hamburger {
    display: block;
    z-index: 999;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: rotate(45deg) translateY(11.5px);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: rotate(-45deg) translateY(-11.5px);
  }
  .nav-menu {
    position: fixed;
    right: 100%;
    top: 0px;
    flex-direction: column;
    width: 100%;
    text-align: center;
    background: rgb(4, 1, 33);
    transition: 0.3s;
  }
  .nav-item {
    padding: 1.5rem 0;
  }

  .nav-menu.active {
    right: 0%;
  }
}
