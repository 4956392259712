.sideBarAdminTools {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.dashboardNavigationItem {
  padding: 15px 0px 15px 10px;
  color: white;
  text-decoration: none;
  display: flex;
  gap: 15px;
  width: 100%;
  background-color: transparent;
  transition: 0.5 sec all;
}

.dashboardNavigationItem:hover {
  background-color: rgba(211, 211, 211, 0.137);
}

.dashboardNavigationItemSelected {
  padding: 15px 0px 15px 10px;
  color: #15181f;
  text-decoration: none;
  display: flex;
  gap: 15px;
  width: 100%;
  background-color: white;
  z-index: 0;
  transition: 0.3sec all;
}

.addAccountButtonUser {
  display: none;
}



.piechartContainer {
  text-align: center;
  margin: auto;
}

.pieChart {
  height: 100%;
  width: 100%;
  max-width: 30vw;
  padding: 30px;
}

.tierContainer {
  max-height: 25%;
}

.tierRow {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  align-items: center;
  margin: auto;
}

.tierTitle {
  display: flex;
  gap: 10px;
  align-items: center;
}

.tierAmount {
  width: 10px;
  height: 10px;
  background-color: #52e1e6;
}

.newAccountFields {
  width: 100%;
  padding: 5px;
}

@media only screen and (max-width: 975px) {


  .addAccountButtonUser {
    display: block;
  }

  .pieChart {
    margin: auto;
    width: 100%;
    max-width: 100vw;
  }
}
