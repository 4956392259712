.sideBarAdminTools {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.dashboardNavigationItem {
  padding: 15px 0px 15px 10px;
  color: white;
  text-decoration: none;
  display: flex;
  gap: 15px;
  width: 100%;
}

.dashboardNavigationItemSelected {
  padding: 15px 0px 15px 10px;
  color: #15181f;
  text-decoration: none;
  display: flex;
  gap: 15px;
  width: 100%;
  background-color: white;
  z-index: 0;
  border-left: red 5px solid;
}

.orderInputFields::placeholder {
  color: grey;
  text-transform: capitalize;
}

.inputFieldsHeaders {
  color: black;
  margin-top: 60px;
  margin-bottom: 0px;
  text-align: left;
}

.inputFieldsHeaders:nth-child(-n + 2) {
  margin-top: 28px;
}

.white {
  background-color: white;
}

.grey {
  background-color: rgba(240, 240, 240, 1);
}

.deleteUser {
  background-color: transparent;
  border: 1px solid red;
  color: red;
  width: 150px;
  border-radius: 5px;
}

.deleteUser:hover {
  background-color: red;
  color: white;
}

.activateUser {
  background-color: transparent;
  border: 1px solid rgb(30, 84, 15);
  color: rgb(30, 84, 15);
  width: 150px;
  border-radius: 5px;
}

.activateUser:hover {
  background-color: rgb(30, 84, 15);
  color: white;
}

.newUserField {
  width: 100%;
  padding: 10px 10px;
  margin: 10px;
}

.userSubmitButton {
  border-radius: 5px;
  width: 75%;
}

.userCloseButton {
  background-color: transparent;
  color: black;
  width: 25%;
  border: 1px solid black;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.797);
}

.dataTableCell {
  font-size: 12px;
  text-align: left;
}

.productTable {
  width: 100%;
  padding: 30px 10px;
}

.productTableHeader {
  border: solid 2px black;
}

.productTableHeaderTitle {
  font-size: 22px;
  text-align: left;
  border-bottom: 2px solid black;
}

.productTableContentRow:hover {
  background-color: lightgrey;
  border-bottom: 1px solid grey;
}

.productTableContentRow:hover > th {
  font-weight: 800;
}

.productTableContent {
  font-weight: 400;
  text-align: left;
  padding: 10px 0px;
}
