.pagination {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  max-width: 600px;
  padding: 10px;
  align-items: center;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  display: flex;
  justify-content: center;
  font-size: 25px;
  padding: 10px;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  color: #337ab7;
}

ul.pagination li:hover {
  background-color: #337ab7;
  color: white;
}

ul.pagination li a {
  text-decoration: none;
  color: inherit;
  font-size: 20px;
}

ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: #337ab7;
}

ul.pagination li a.active {
  color: blue;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
