.sideBarAdminTools {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.dashboardNavigationItem {
  padding: 15px 0px 15px 10px;
  color: white;
  text-decoration: none;
  display: flex;
  gap: 15px;
  width: 100%;
  background-color: transparent;
  transition: 0.5 sec all;
}

.dashboardNavigationItem:hover {
  background-color: rgba(211, 211, 211, 0.137);
}

.dashboardNavigationItemSelected {
  padding: 15px 0px 15px 5px;
  color: #15181f;
  text-decoration: none;
  display: flex;
  gap: 15px;
  width: 100%;
  background-color: white;
  z-index: 0;
  transition: 0.3sec all;
  font-weight: 800;
}

.dashboardMainPanel {
  width: 100%;
  background-color: #f0f2f7;
  /* overflow: scroll; */
}

.dashboardSection {
  width: 100%;
  margin: 5px 0px;
}

.dashboardSectionUser {
  width: 100%;
  overflow-y: scroll;
  margin: 0px;
  padding-bottom: 120px;
}

.userDashboardHeader {
  background-color: white;
  padding: 5px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
}

.addAccountButtonUser {
  display: none;
}

.accountsContainer {
  display: flex;
  flex-direction: column;
  background-color: #f0f2f7;
  border: 1px solid #a3c3c7;
  overflow-y: scroll;
  height: 50vh;
  width: 50%;
}

.accountsContainerUser {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  overflow-y: scroll;
}

.StatsMainContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #f0f2f7;
  padding: 10px 10px 150px 10px;
  z-index: 1;
  overflow-y: scroll;
  width: 100%;
  margin: auto;
}

.statsContainer {
  width: 100%;
  max-width: 100%;
  margin: auto;
  height: 100%;
}

.piechartContainer {
  text-align: center;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  /* flex-flow: column wrap; */
  justify-content: space-evenly;
  gap: 0px;
}

.pieChart {
  height: 100%;
  width: 100%;
  max-width: 30vw;
  padding: 30px;
}

.tierContainer {
  max-height: 25%;
}

.tierRow {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  align-items: center;
  margin: auto;
}

.tierTitle {
  display: flex;
  gap: 10px;
  align-items: center;
}

.tierAmount {
  width: 10px;
  height: 10px;
  background-color: #52e1e6;
}

.announcementsContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #a3c3c7;
  width: 50%;
  overflow-y: scroll;
  height: 50vh;
}

.notification {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid #a3c3c7;
  padding: 10px;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.notificationDelete {
  background-color: grey;
  color: lightgrey;
  transition: all 0.3s ease-in-out;
}

.chart {
  background: #ffffff;
  border: 1px solid #a3c3c7;
  margin: auto;
  height: 50vh;
}

@media only screen and (max-width: 975px) {
  .dashboardMainPanel {
    padding: 0px;
    /* overflow-y: scroll; */
  }

  .userDashboardHeader {
    display: none;
  }

  .announcementsContainer {
    width: 100%;
  }

  .accountsContainer {
    width: 100%;
  }

  .statsContainerMobile {
    display: none;
  }

  .dashboardSection {
    width: 100%;
    flex-wrap: wrap;
  }

  .accountsContainer {
    height: 50vh;
  }

  .accountsContainerUser {
    overflow-y: auto;
    width: 100%;
  }

  .addAccountButtonUser {
    display: block;
  }

  .chart {
    display: none !important;
  }
}
