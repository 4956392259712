:root {
  --dark-blue: #15181f;
  --ghost-white: #f0f2f7;
  --white: white;
  --msn-dark-blue: #031726;
  --border-color: #a3c3c7;
  --dark-theme-bg: #2d313b;
  --lime-green: #13cc62;
  --msn-red: #fe003c;
  --dodger-blue: #3898ec;
  --msn-pink: #fd0d95;
  --5: 5px;
  --hover-pink: rgba(253, 13, 149, .11);
  --blue-violet: #8938ec;
}

.w-layout-layout {
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-auto-columns: 1fr;
  justify-content: center;
  padding: 20px;
}

.w-layout-cell {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

.nav-column {
  height: 100vh;
  background-color: var(--dark-blue);
  position: relative;
}

.section {
  height: auto;
  max-height: none;
  min-height: auto;
  position: relative;
  overflow: hidden;
}

.columns {
  height: 100vh;
  position: relative;
}

.data-column {
  height: 100vh;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  background-color: var(--dark-blue);
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  display: block;
  overflow: scroll;
}

.navbar {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  align-items: center;
  display: block;
}

.nav-container {
  flex: 1;
  align-items: flex-start;
  display: flex;
}

.nav-menu {
  width: 100%;
  color: var(--ghost-white);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 0;
  display: flex;
}

.nav-link {
  color: var(--white);
  text-align: left;
  text-transform: none;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 16px;
  text-decoration: none;
  position: relative;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.nav-link:visited {
  color: var(--ghost-white);
}

.nav-link.lower-link {
  width: 250px;
  background-color: var(--white);
  color: var(--dark-blue);
  text-align: center;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: 400;
  transition: all .2s;
}

.nav-link.lower-link:hover {
  background-color: var(--ghost-white);
}

.live-data-number {
  color: var(--msn-dark-blue);
  text-align: center;
  font-weight: 300;
}

.live-data-upper-column {
  width: 100%;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  background-color: rgba(0, 0, 0, 0);
  flex: 0 auto;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 8px;
  margin-bottom: 40px;
  display: flex;
}

.blue-text {
  color: #4b95f2;
  text-align: center;
  font-size: 22px;
}

.body {
  height: 100%;
  font-family: Poppins, sans-serif;
}

.live-data-column {
  height: auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-style: none;
  border-width: 1px 1px 10px;
  border-color: #000 #000 var(--msn-dark-blue);
  background-color: var(--white);
  color: var(--msn-dark-blue);
  border-radius: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.green-text {
  color: #1ab965;
  text-align: center;
  font-size: 22px;
}

.purple-text {
  color: #c64bf2;
  text-align: center;
  font-size: 22px;
}

.red-text {
  color: red;
  text-align: center;
  font-size: 22px;
}

.notifications-section {
  height: 600px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
}

.data-area-header-row {
  width: 100%;
  height: auto;
  border-style: none none solid;
  border-width: 1px;
  border-color: var(--border-color);
  background-color: var(--dark-theme-bg);
  border-radius: 1px;
}

.chart-area {
  height: 100%;
  border: 1px none var(--dark-blue);
  background-color: var(--dark-blue);
  color: var(--dark-theme-bg);
  border-radius: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 32px;
  display: flex;
}

.text-block {
  color: var(--white);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
}

.notification-row {
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  color: #333;
  border-radius: 1px;
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  padding: 10px;
  display: flex;
}

.notification-details {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  display: flex;
}

.notification-image {
  width: 22px;
  height: 22px;
}

.account {
  width: auto;
  color: var(--msn-dark-blue);
  font-size: 18px;
}

.accounts-list-area {
  height: 100%;
  border: 1px none var(--border-color);
  background-color: var(--dark-blue);
  color: var(--dark-theme-bg);
  border-radius: 2px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 32px;
  display: flex;
}

.scrolling-div {
  width: 100%;
  height: 100%;
  background-color: var(--dark-theme-bg);
  margin-bottom: 0;
  margin-right: 0;
  padding-right: 0;
  overflow: scroll;
}

.account-row {
  width: 100%;
  border-bottom: 1px solid var(--ghost-white);
  background-color: var(--white);
  color: #333;
  border-radius: 0;
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  padding: 10px;
  display: flex;
}

.account-action-block {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: center;
  display: flex;
}

.html-embed {
  width: 100%;
  height: 100%;
  background-color: var(--dark-blue);
}

.chart-section {
  height: 600px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: var(--dark-theme-bg);
  border-radius: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px 10px;
  padding: 10px;
  display: flex;
}

.image {
  width: 200px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.logo-container {
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
}

.menu-item-image {
  width: 22px;
  height: 22px;
}

.menu-item-row {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding: 16px 10px;
  text-decoration: none;
  display: flex;
}

.menu-item-row:hover {
  background-color: #565758;
}

.logout-nav-link, .logout-nav-link-mobile {
  color: var(--ghost-white);
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 0;
  padding: 0;
  font-size: 22px;
  font-weight: 300;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.menu-item-row-logout {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding: 16px 10px;
  display: none;
}

.menu-item-row-logout:hover {
  background-color: #565758;
}

.filters-bar {
  height: 60px;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0);
  justify-content: flex-start;
  align-items: center;
  padding-left: 8px;
  display: flex;
  position: relative;
}

.information-bar {
  height: 100%;
  max-width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: rgba(0, 0, 0, 0);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  position: relative;
}

.shipping-icon {
  width: 42px;
  height: 42px;
}

.non-shipped-orders-container {
  grid-column-gap: 8px;
  grid-row-gap: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.shipping-status-number {
  font-size: 22px;
}

.shipping-status-container {
  grid-column-gap: 42px;
  align-items: center;
  margin-right: 10px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.semester-total {
  padding-left: 0;
  font-size: 22px;
  position: relative;
  top: auto;
  bottom: auto;
  left: 0%;
  right: auto;
}

.shipped-orders-container {
  grid-column-gap: 8px;
  grid-row-gap: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.filters-bar-button-container {
  grid-column-gap: 32px;
  align-items: center;
  display: flex;
}

.div-block-2 {
  align-items: center;
  margin-right: 10px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.information-and-filters {
  border-bottom: 5px none var(--dark-blue);
  border-left: 5px none var(--ghost-white);
  background-color: var(--white);
  color: var(--dark-blue);
  border-radius: 0;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
  box-shadow: 1px 6px 4px -2px #d6d6d6;
}

.order-details {
  width: 100%;
  max-width: 100%;
  background-color: var(--white);
  border-radius: 10px;
  margin-top: 0;
  margin-bottom: 0;
  transition: all .2s;
  box-shadow: 0 3px 6px -3px #616161;
}

.order-details:hover {
  padding: 40px 20px;
  box-shadow: 0 6px 12px #b3b3b3;
}

.div-block-4 {
  width: 16px;
  height: 16px;
  background-color: var(--lime-green);
  border-radius: 50%;
}

.order-shipping-status-text {
  grid-column-gap: 8px;
  align-items: center;
  margin-left: 0;
  display: flex;
}

.text-block-2 {
  font-size: 18px;
}

.order-upper-bar {
  width: 100%;
  padding: 10px;
}

.tracking-number-text {
  text-align: right;
}

.text-block-3 {
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: 700;
}

.columns-2 {
  padding: 10px;
}

.order-button-container {
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px 10px 10px;
  display: flex;
}

.all-orders-scrollable {
  max-height: 100vh;
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  flex-flow: column;
  align-content: stretch;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  padding: 20px 10px 220px;
  display: flex;
  overflow: scroll;
}

.export-button {
  clear: none;
  background-color: var(--msn-red);
  text-align: left;
  flex: 0 auto;
  position: relative;
}

.heading {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.div-block-5 {
  grid-column-gap: 32px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.search-button {
  width: 150px;
  text-align: center;
  border-radius: 5px;
}

.clear-button {
  width: 150px;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.purchases-data-column {
  height: 100vh;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  background-color: var(--ghost-white);
  text-align: left;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-left: 0;
  padding-right: 0;
  display: block;
  overflow: hidden;
}

.form {
  height: 50px;
  justify-content: flex-end;
  display: flex;
}

.text-field {
  width: 250px;
  height: 100%;
  background-color: #f1f1f1;
}

.order-action-buttons {
  width: 250px;
  height: 40px;
  background-color: var(--dodger-blue);
  color: var(--white);
  text-align: center;
  flex: 1;
  text-decoration: none;
}

.order-action-buttons:visited {
  color: var(--white);
  text-decoration: none;
}

.dropdown-toggle {
  padding-top: 0;
  padding-bottom: 0;
}

.event-list-title-bar {
  height: 100%;
  max-width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: rgba(0, 0, 0, 0);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  position: relative;
}

.event-data-column {
  height: 100vh;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  background-color: var(--ghost-white);
  text-align: left;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-left: 0;
  padding-right: 0;
  display: block;
  overflow: hidden;
}

.events-title-section {
  width: 100%;
  border-bottom: 5px none var(--dark-blue);
  border-left: 5px none var(--ghost-white);
  background-color: var(--white);
  color: var(--dark-blue);
  border-radius: 0;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
  box-shadow: 1px 6px 4px -2px #d6d6d6;
}

.event-list-scrollable {
  max-height: 100vh;
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  flex-flow: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  padding: 20px 10px 220px;
  display: flex;
  overflow: scroll;
}

.event-card {
  width: 300px;
  height: 400px;
  max-height: 400px;
  background-color: var(--white);
  border-radius: 10px;
  position: relative;
  box-shadow: 3px 3px 10px #d4d4d4;
}

.event-image {
  width: 100%;
  height: 50%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.event-title {
  margin-left: 0;
  margin-right: 0;
  font-size: 18px;
  font-weight: 900;
}

.event-details-container {
  margin: 10px;
  overflow: scroll;
}

.event-date-time {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700;
}

.div-block-8 {
  grid-column-gap: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.delete-event {
  width: 16px;
  height: 16px;
  margin-top: 10px;
  margin-left: 10px;
  position: absolute;
}

.section-2 {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(129deg, var(--dark-theme-bg), var(--dark-blue));
  -webkit-text-fill-color: inherit;
  background-clip: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.image-3 {
  width: 25vw;
  margin: 20px auto 40px;
}

.login-form {
  width: 100%;
}

.order-info {
  width: 100%;
  outline-offset: 0px;
  outline: 3px #333;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  display: flex;
  position: static;
}

.order-tier-select {
  width: 50%;
  min-width: auto;
  background-color: var(--white);
  justify-content: space-between;
  padding-top: 0;
  display: flex;
}

.order-product-list-container {
  height: 100%;
  max-height: 100%;
  max-width: 900px;
  grid-row-gap: 64px;
  flex-flow: column;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 0 20px 400px;
  display: flex;
  overflow: scroll;
}

.div-block-10 {
  grid-column-gap: 42px;
  grid-row-gap: 42px;
  justify-content: center;
  align-items: center;
  font-family: Open Sans, sans-serif;
  display: flex;
  position: relative;
}

.tier-select-button {
  color: var(--dark-blue);
  background-color: rgba(0, 0, 0, 0);
  border-radius: 4px;
  font-size: 20px;
  font-weight: 700;
  transition: all .2s;
}

.tier-select-button:hover {
  color: var(--dark-blue);
  border-radius: 3px;
  font-weight: 700;
}

.tier-select-button:active {
  border-radius: 4px;
}

.tier-select-button:focus-visible {
  background-color: var(--dodger-blue);
  color: var(--white);
  border-radius: 4px;
}

.tier-select-button[data-wf-focus-visible] {
  background-color: var(--dodger-blue);
  color: var(--white);
  border-radius: 4px;
}

.tier-select-button.selected-tierbutton {
  background-color: var(--dark-blue);
  color: var(--ghost-white);
}

.product-price {
  color: var(--msn-red);
  font-size: 18px;
}

.product-name {
  color: var(--dark-blue);
  font-size: 18px;
  font-weight: 400;
}

.product-amount-ordered {
  width: 4ch;
  color: var(--dark-blue);
  text-align: center;
  border-bottom: 1px #000;
  margin-left: auto;
  margin-right: 0;
  font-size: 32px;
  line-height: 48px;
}

.product {
  align-items: center;
  display: flex;
}

.checkout-form-input {
  max-width: 500px;
  margin-right: 100px;
}

.completed-screen {
  height: 100vh;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  background-color: var(--ghost-white);
  text-align: left;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-left: 0;
  padding-right: 0;
  display: block;
  overflow: hidden;
}

.completed-top-section {
  height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.return-to-dashboard {
  border-radius: 4px;
  margin-top: 20px;
}

.order-summary-container {
  height: 10vh;
  background-color: var(--dodger-blue);
  justify-content: center;
  align-items: center;
  display: flex;
}

.order-summary {
  height: auto;
  color: var(--white);
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 22px;
}

.logo-complete {
  width: 128px;
  height: 128px;
  object-fit: cover;
  margin-top: 20px;
  margin-bottom: 40px;
}

.order-complete {
  font-size: 32px;
  line-height: 42px;
}

.order-summary-scroll-container {
  height: 40vh;
  grid-row-gap: 0px;
  flex-direction: column;
  align-items: center;
  display: flex;
  overflow: scroll;
}

.product-text-summary {
  font-size: 22px;
}

.product-summary {
  width: 100%;
  background-color: var(--white);
  margin: 0;
  padding: 20px 10px;
}

.div-block-11 {
  width: 30vw;
  background-color: var(--white);
  border-radius: 10px;
  flex-direction: column;
  padding: 20px;
  display: flex;
}

.text-block-5 {
  color: #7e7e7e;
  padding-top: 10px;
  padding-bottom: 10px;
}

.submit-button {
  border-radius: 4px;
}

.account-scrolling-div {
  width: 100%;
  height: 100%;
  background-color: var(--dark-theme-bg);
  margin-bottom: 0;
  margin-right: 0;
  padding-right: 0;
  overflow: hidden;
}

.form-block-3 {
  width: 50%;
  max-width: 700px;
  margin-bottom: 0;
}

.text-field-3 {
  width: 100%;
  max-width: 900px;
  background-color: var(--ghost-white);
  margin: 20px auto;
  padding: 10px;
}

.form-2 {
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.nav-bar {
  background-color: var(--dark-blue);
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-family: Open Sans, sans-serif;
  display: flex;
}

.image-4 {
  width: auto;
  height: 50px;
}

.nav-link-container {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  align-items: center;
  display: flex;
}

.notifications-bar {
  height: 70px;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  border-top: 1px none var(--ghost-white);
  border-bottom: 2px solid var(--dark-blue);
  background-color: var(--ghost-white);
  color: var(--white);
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  font-family: Open Sans, sans-serif;
  display: flex;
}

.notifications-bar.checkout-bar {
  display: none;
}

.cart-total {
  color: var(--dark-blue);
  font-size: 20px;
  font-weight: 600;
  display: none;
}

.hamburger-container {
  display: none;
}

.order-selection-bar {
  height: 70px;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  border-top: 1px none var(--ghost-white);
  border-bottom: 1px solid var(--dark-blue);
  color: var(--white);
  background-color: #ffca43;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  font-family: Open Sans, sans-serif;
  display: flex;
  position: relative;
}

.please-select-text {
  color: var(--dark-blue);
  font-size: 20px;
  display: none;
}

.column-5 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.div-block-13 {
  flex: 0 auto;
}

.image-5 {
  width: 30px;
  height: 30px;
}

.column-6 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  display: flex;
}

.div-block-14 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  display: flex;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}

.text-span, .text-span-2, .text-span-3 {
  font-size: 14px;
  font-weight: 400;
}

.text-block-8 {
  color: var(--dark-blue);
  font-size: 22px;
  font-weight: 700;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 60px;
}

.heading-search-input {
  width: 350px;
  margin-bottom: 0;
}

.view-company-large-heading {
  color: var(--msn-dark-blue);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.body-2 {
  font-family: Poppins, sans-serif;
}

.div-block-15 {
  width: 100%;
  max-height: none;
  border: 1px #000;
  flex-direction: column;
  flex: 0 auto;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: scroll;
}

.text-block-9 {
  padding: 0 10px 10px 0;
}

.order-history-row {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  align-items: center;
  padding: 10px;
  display: flex;
}

.view-company-purchase-button {
  color: var(--msn-dark-blue);
  background-color: rgba(255, 255, 255, 0);
  text-decoration: underline;
}

.view-company-purchase-button:hover {
  background-color: rgba(255, 255, 255, 0);
}

.selection-bar {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  align-items: center;
  display: flex;
}

.quick-stack {
  min-height: 100vh;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: var(--ghost-white);
  padding: 0;
}

.cell {
  border-style: none none none solid;
  border-width: 1px;
  border-color: black black black var(--dark-theme-bg);
  padding: 10px 60px;
}

.section-heading {
  width: 100%;
  border-style: none none solid;
  border-width: 1px;
  border-color: var(--dark-theme-bg) var(--dark-theme-bg) #bdbdbd;
  margin-top: 10px;
  margin-bottom: 10px;
}

.div-block-19 {
  width: 75px;
  height: 5px;
  background-color: var(--dodger-blue);
}

.div-block-20 {
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  display: flex;
}

.cell-2 {
  padding: 10px 60px 20px;
}

.company-order-details-conatiner {
  width: 100%;
  max-height: none;
  background-color: var(--white);
  border: 1px #000;
  flex-direction: column;
  flex: 0 auto;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: visible;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.view-company-button {
  width: 250px;
  background-color: var(--msn-pink);
  color: var(--white);
  text-align: center;
  border: 1px #000;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.view-company-button:hover {
  background-color: var(--msn-pink);
  border-style: none;
}

.div-block-21 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.body-3 {
  min-height: 100vh;
  background-color: var(--ghost-white);
  font-family: Poppins, sans-serif;
}

.account-order-button {
  width: auto;
  max-width: none;
  min-width: 150px;
  border: 1px solid var(--msn-pink);
  border-radius: var(--5);
  color: var(--msn-dark-blue);
  text-align: center;
  background-color: rgba(255, 255, 255, 0);
  padding: 10px 20px;
  text-decoration: none;
  transition: all .2s;
}

.account-order-button:hover {
  background-color: var(--hover-pink);
}

.quick-stack-2 {
  height: 100%;
  min-height: 100%;
}

.section-3 {
  height: auto;
  min-height: 100%;
  overflow: scroll;
}

.section-4 {
  min-height: 100vh;
  background-color: var(--ghost-white);
  color: var(--msn-dark-blue);
  font-family: Poppins, sans-serif;
}

.product-row {
  width: 100%;
  min-height: 75px;
  border-bottom: 1px solid var(--ghost-white);
  background-color: var(--white);
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.product-amount-adjustment-container {
  height: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.product-subtraction {
  width: 40px;
  height: 40px;
  border: 1px solid var(--msn-pink);
  background-color: var(--white);
  color: var(--msn-pink);
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.product-subtraction:hover {
  background-color: var(--hover-pink);
}

.product-amount-entry {
  width: 100px;
  height: 45px;
  background-color: var(--ghost-white);
  color: var(--msn-dark-blue);
  margin-bottom: 0;
  padding: 10px 20px;
  font-size: 16px;
}

.form-block-4 {
  margin-bottom: 0;
}

.product-row-price {
  color: var(--msn-pink);
  font-family: Varela Round, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.div-block-22 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.form-block-5 {
  width: 100%;
}

.field-label {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.cell-5 {
  justify-content: flex-start;
}

.shop-cart-button-container {
  display: none;
}

.product-in-cart-container {
  width: 100%;
  height: 50px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  display: flex;
}

.product-in-cart-row {
  width: 100%;
  height: 100%;
  min-height: 50px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border-bottom: 1px solid var(--border-color);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.product-in-cart-name {
  align-items: center;
  font-size: 18px;
  display: flex;
}

.product-in-cart-quantity {
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.text-block-10 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 26px;
}

.quick-stack-3 {
  height: auto;
  min-height: 100vh;
}

.quick-stack-4 {
  height: auto;
  min-height: 100vh;
  background-color: var(--ghost-white);
}

.checkout-infor-entry-container {
  width: 100%;
  height: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  overflow: scroll;
}

.form-label {
  font-size: 18px;
  font-weight: 400;
}

.submit-button-2 {
  background-color: var(--msn-pink);
}

.form-input-field-container {
  width: 100%;
}

.form-input-field-container.city-state-zip {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.div-block-23 {
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  display: flex;
}

.cell-6 {
  grid-column-gap: 80px;
  grid-row-gap: 80px;
}

.cell-7 {
  background-color: var(--white);
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.div-block-24 {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.image-6 {
  width: 300px;
  height: 300px;
  border-radius: 100%;
}

.body-4 {
  background-color: var(--ghost-white);
  font-family: Poppins, sans-serif;
}

.heading-2 {
  font-weight: 400;
}

.form-block-6 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.form-3 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  display: flex;
}

.section-5 {
  background-color: var(--ghost-white);
  padding-top: 40px;
  padding-bottom: 100px;
}

.container {
  flex-direction: column;
  display: flex;
}

.div-block-25 {
  justify-content: space-between;
  display: flex;
}

.image-7 {
  width: 25px;
  height: 25px;
}

.account-row-delete-button {
  border: 1px solid var(--msn-pink);
  border-radius: var(--5);
  background-color: var(--msn-red);
  color: var(--ghost-white);
  padding: 5px 10px;
  text-decoration: none;
  transition: all .2s;
}

.account-row-delete-button:hover {
  background-color: var(--hover-pink);
}

.div-block-26 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 991px) {
  .nav-column {
    height: 11vh;
  }

  .nav-container {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .nav-menu {
    flex-flow: row;
    justify-content: space-around;
  }

  .nav-link {
    font-size: 16px;
  }

  .live-data-upper-column {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex: 0 auto;
    padding-bottom: 20px;
  }

  .live-data-column {
    height: 150px;
  }

  .chart-section {
    display: none;
  }

  .menu-item-image {
    display: none;
    overflow: visible;
  }

  .menu-item-row {
    justify-content: center;
    display: block;
  }

  .logout-nav-link {
    display: none;
    position: relative;
  }

  .logout-nav-link-mobile {
    margin: 22px 32px 0 0;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: auto;
    right: 0%;
  }

  .menu-item-row-logout {
    justify-content: center;
    display: none;
  }

  .checkout-form-input {
    max-width: none;
  }

  .notifications-bar.checkout-bar {
    justify-content: flex-end;
    display: flex;
  }

  .selection-bar {
    margin-left: auto;
    margin-right: auto;
  }

  .account-order-button {
    width: 250px;
  }

  .cell-5 {
    flex-direction: column;
    display: none;
  }

  .shop-cart-button-container {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    background-color: var(--ghost-white);
    justify-content: flex-end;
    padding-right: 20px;
    display: flex;
  }

  .shop-cart-button, .search-cart-button {
    width: 30px;
    height: 30px;
  }

  .image-6 {
    width: 200px;
    height: 200px;
  }

  .heading-2 {
    text-align: center;
  }

  .account-row-delete-button {
    width: 250px;
  }
}

@media screen and (max-width: 767px) {
  .nav-link.lower-link {
    text-align: center;
    padding: 10px 20px;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    display: block;
  }

  .live-data-number {
    margin-top: 0;
    margin-bottom: 0;
  }

  .blue-text {
    font-size: 18px;
  }

  .live-data-column {
    height: 125px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .green-text, .purple-text, .red-text {
    font-size: 18px;
  }

  .notifications-section {
    height: 1200px;
    flex-direction: column;
  }

  .product-name {
    width: 18ch;
  }

  .nav-link-container {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .notifications-bar {
    height: 75px;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .hamburger-container {
    display: block;
  }

  .selection-bar {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .cell-3 {
    padding-bottom: 60px;
    padding-left: 0;
  }

  .cell-4 {
    padding-left: 0;
  }

  .product-amount-adjustment-container {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .product-subtraction {
    width: 30px;
    height: 30px;
  }

  .product-row-price {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .shop-cart-button-container {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .shop-cart-button, .search-cart-button {
    width: 20px;
    height: 20px;
  }

  .quick-stack-5 {
    grid-column-gap: 120px;
    grid-row-gap: 120px;
  }

  .form-3 {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 479px) {
  .nav-column {
    height: 150px;
  }

  .section {
    width: 100%;
    max-width: 100%;
  }

  .nav-link {
    color: var(--white);
    font-size: 22px;
    font-weight: 400;
  }

  .nav-link.lower-link {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .live-data-number {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
  }

  .live-data-upper-column {
    grid-column-gap: 8px;
    flex-flow: column;
    align-content: center;
  }

  .blue-text {
    font-size: 16px;
  }

  .live-data-column {
    height: 75px;
    flex-wrap: nowrap;
    padding: 0;
  }

  .green-text, .purple-text, .red-text {
    font-size: 16px;
  }

  .notifications-section {
    height: 900px;
    grid-column-gap: 0px;
    grid-row-gap: 32px;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  .chart-area {
    height: 100%;
    padding-bottom: 0;
  }

  .account {
    max-height: 100px;
    max-width: none;
    font-size: 16px;
    overflow: hidden;
  }

  .accounts-list-area {
    height: 100%;
    padding-bottom: 0;
  }

  .scrolling-div {
    height: 600px;
  }

  .account-row {
    width: 100%;
    height: auto;
    max-height: none;
    max-width: 100vw;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }

  .menu-item-row {
    background-color: #3a3a3a;
  }

  .logout-nav-link, .logout-nav-link-mobile {
    display: none;
  }

  .icon {
    color: var(--ghost-white);
  }

  .menu-item-row-logout {
    background-color: #3a3a3a;
  }

  .shipped-orders-container {
    flex-direction: row;
  }

  .section-2 {
    justify-content: flex-start;
    padding: 20px 10px;
  }

  .image-3 {
    width: 100vw;
  }

  .login-form {
    width: 100%;
  }

  .order-product-list-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .div-block-10 {
    margin-top: 12px;
  }

  .product-price {
    font-size: 14px;
  }

  .product-name {
    text-align: center;
    font-size: 18px;
  }

  .product-amount-ordered {
    font-size: 22px;
  }

  .order-complete {
    text-align: center;
  }

  .div-block-11 {
    width: 100%;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .text-field-2 {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .form-block-2 {
    width: 100%;
  }

  .account-scrolling-div {
    height: 600px;
  }

  .nav-link-container {
    display: none;
  }

  .notifications-bar {
    height: 75px;
    justify-content: center;
    display: flex;
  }

  .view-company-large-heading {
    width: 100%;
  }

  .div-block-15 {
    max-height: none;
    overflow: visible;
  }

  .selection-bar {
    display: flex;
  }

  .section-heading {
    width: 100%;
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;
  }

  .company-order-details-conatiner {
    max-height: none;
    overflow: visible;
  }

  .div-block-21 {
    margin-left: auto;
    margin-right: auto;
  }

  .body-3 {
    height: auto;
    max-height: none;
    max-width: 100vw;
    padding-bottom: 200px;
  }

  .account-order-button {
    width: 150px;
    min-width: 150px;
    margin-left: auto;
    margin-right: auto;
  }

  .quick-stack-2 {
    width: auto;
    height: auto;
    max-width: 100vw;
    min-height: auto;
    padding: 0;
  }

  .section-3 {
    width: auto;
    max-width: 100vw;
    min-height: 100vh;
  }

  .product-row {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .product-row-price {
    text-align: center;
    font-size: 18px;
  }

  .div-block-22 {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-direction: column;
  }

  .shop-cart-button-container {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .checkout-infor-entry-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .form-input-field-container.city-state-zip {
    flex-direction: column;
  }

  .cell-6 {
    grid-column-gap: 80px;
    grid-row-gap: 80px;
  }

  .cell-7 {
    align-items: center;
  }

  .checkbox {
    width: 30px;
    height: 30px;
  }

  .checkbox-field {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    align-items: center;
    display: flex;
  }

  .account-row-delete-button {
    width: 150px;
    min-width: 150px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .div-block-26 {
    flex-direction: column;
  }
}

#w-node-b8f24944-ee26-a454-4b2d-7b9a5d77515b-6b7f73e7 {
  grid-template-rows: auto;
  grid-template-columns: 2fr 1fr;
}

#w-node-d3d585a4-a1cf-45b3-adbd-d1c1eea59e99-7fd736be {
  grid-template-rows: auto;
  grid-template-columns: 1fr .5fr;
}

#w-node-d8d88979-025c-366b-f114-2788e9a3b4fc-dddc6260 {
  grid-template-rows: auto;
  grid-template-columns: 1.5fr .75fr;
}

#w-node-c8697c73-dc5b-8256-6ee0-7762e7750989-cb8a664c {
  grid-template-rows: auto;
  grid-template-columns: 1fr .5fr;
}

#w-node-_6ffa5c51-674f-2d5b-0f9c-104ebddf9932-dfb070ee {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 991px) {
  #w-node-b8f24944-ee26-a454-4b2d-7b9a5d77515b-6b7f73e7 {
    grid-template-rows: auto;
    grid-template-columns: 2fr;
  }

  #w-node-d8d88979-025c-366b-f114-2788e9a3b4fc-dddc6260, #w-node-c8697c73-dc5b-8256-6ee0-7762e7750989-cb8a664c {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  #w-node-_5700196d-8176-3713-eb51-a4f6105aee68-cb8a664c {
    order: 9999;
  }
}

@media screen and (max-width: 767px) {
  #w-node-d3d585a4-a1cf-45b3-adbd-d1c1eea59e99-7fd736be {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  #w-node-_197bf510-284c-43a8-b608-3eedd2f81183-7fd736be {
    order: 9999;
  }

  #w-node-_6ffa5c51-674f-2d5b-0f9c-104ebddf9932-dfb070ee {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479px) {
  #w-node-d3d585a4-a1cf-45b3-adbd-d1c1eea59e99-7fd736be {
    grid-template-rows: auto;
    grid-template-columns: .75fr;
  }

  #w-node-_197bf510-284c-43a8-b608-3eedd2f81183-7fd736be {
    order: 9999;
  }

  #w-node-d8d88979-025c-366b-f114-2788e9a3b4fc-dddc6260 {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  #w-node-_965e181a-080e-f94d-cd49-306107cd13c6-dddc6260 {
    order: 9999;
  }
}


