@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

.App {
  font-family: "Noto Sans", sans-serif !important;
  color: black;
}

.bold {
  font-weight: 800 !important;
}

.App-logo {
  height: 50px !important;
  width: 50px !important;
  pointer-events: none;
}

.loginOrderInputFields {
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: none;
  border-bottom: 2px red solid;
  color: black;
  font-size: 22px;
  font-weight: 800;
}

.loginOrderInputFields::placeholder {
  color: rgb(56, 56, 56);
  font-weight: 400;
}

.loginButton {
  max-width: 150px;
  border: 5px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  /* float: left; */
  width: 100%;
}

.selectionListCompanies::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(21, 21, 21);
  opacity: 1; /* Firefox */
}

.selectionListCompanies:focus::placeholder {
  color: transparent;
  padding: 10px;
}

.searchBarContainer {
  width: 100%;
  height: 100%;
  height: 60px;
}

.searchBarContainer > img {
  position: absolute;
  top: 20px;
  right: 30px;
}

.show {
  display: block;
}

.hide {
  display: none;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.fullHeader {
  position: relative;
  width: 100%;
  display: none;
}

.App-header {
  background-color: #303030;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10px 10px;
  height: 10vh;
  z-index: 999;
  max-height: 75px;
  margin: auto;
}

.App-link {
  color: #61dafb;
}

.trainingContainer {
  background-color: #303030b5;
  padding: 10px;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10vh;
  max-width: 900px;
}

.trainingContainer:nth-child(n + 1) {
  margin-top: 0vh;
}

.trainingHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.className {
  color: #d2282e;
  text-align: right;
  font-weight: 400;
  font-size: 24px;
}

.checkboxScale {
  transform: scale(1.5);
  margin-right: 10px;
}

.treatmentSelections {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectionList {
  width: 100%;
  height: 50px;
  font-size: 22px;
  margin-top: 10px;
  border: none;
  border-bottom: 1px solid grey;
  margin-top: 30px;
}

.texture {
  background-color: #000000;
  background-image: url("https://www.transparenttextures.com/patterns/black-thread.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  max-height: 100vh;
  width: 100%;
  margin: auto;
  overflow-y: scroll;
}

.contactBG {
  background-color: #000000;
  background-image: url("https://www.transparenttextures.com/patterns/black-thread.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  width: 100%;
  padding: 10px;
  min-height: 100vh;
  z-index: 1;
}

.greyTexture {
  background-color: #303030;
  background-image: url("https://www.transparenttextures.com/patterns/buried.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

/* SPINNER */
/* ////////////////////////////////// */
.lds-facebook {
  display: flex;
  justify-content: center;
  position: relative;
  margin: auto;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: rgb(24, 24, 24);
  border: solid white 1px;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

/* MENU */
/* ////////////////////////////////// */

.menuList {
  justify-content: space-around;
  align-items: center;
}

.menuList > li > a {
  font-size: 32px;
  font-weight: 800;
  font-family: serif;
  color: white;
  text-align: center;
}

.MenuPopup {
  z-index: 99;
  display: block;
  position: absolute;
  transition: 0.2s all ease-in-out;
  width: 100vw;
  height: 95vh;
  background-color: #d2282e;
  display: none;
  z-index: 1;
  position: relative;
  opacity: 100;
  backdrop-filter: blur(1rem);
}

.none {
  display: none;
}

/* INPUT FIELDS */
/* ////////////////////////////////// */

.productListRow {
  margin-bottom: 60px;
  font-size: 22px;
  justify-content: center;
  align-items: center;
}

.orderButtonContainer {
  width: 100%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.orderButton {
  background-color: #d2282e;
  color: white;
  font-size: 32px;
  text-align: center;
  border-radius: 50px;
  padding: 10px 30px;
  font-weight: 800;
  text-decoration: none;
  text-transform: none;
  text-emphasis: none;
}

.editButton {
  background-color: #444444;
  color: white;
  font-size: 16px;
  text-align: center;
  border-radius: 50px;
  padding: 5px 10px;
  font-weight: 800;
}

.overviewRows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#menuToggle {
  display: block;
  position: relative;
  top: 50px;
  left: 50px;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;

  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: #d2282e;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: absolute;
  width: 300px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;

  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul {
  transform: none;
}

/* STRIPE //////////////////////////////////////////////////////// */
.result-message {
  line-height: 22px;
  font-size: 16px;
  color: white;
}
.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}
.hidden {
  display: none;
}
#card-error {
  color: #d2282e;
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}
#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
#payment-request-button {
  margin-bottom: 32px;
}
/* Buttons and links */
button {
  background: #d2282e;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}
/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #d2282e;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #d2282e;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  form {
    width: 100%;
  }
}

.neocutisHeaders {
  color: white;
  font-size: 32px;
  margin: 90px 0px 30px 0px;
  border-bottom: 5px solid white;
  word-wrap: normal;
  font-weight: 800;
}

.neocutisHeaders:nth-child(1) {
  margin-top: 20px;
}

.resendTrackingEmail {
  max-width: 150px;
  border-radius: 5px;
  background-color: #687864;
  color: white;
  padding: 5px 10px;
  text-align: center;
}

.resendTrackingEmail:hover {
  background-color: #849181;
  transition: all 0.3s;
}

.resendTrackingEmailLoading {
  max-width: 150px;
  border-radius: 5px;
  background-color: grey;
  color: white;
  padding: 5px 10px;
  text-align: center;
}

.resendTrackingEmailLoading:hover {
  background-color: grey;
  transition: all 0.3s;
}

.viewOrderDetailsAdminCenter {
  background-color: #5085a5;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  max-width: 150px;
}

.viewOrderDetailsAdminCenter:hover {
  background-color: #6fa0be;
  transition: all 0.3s;
}

.viewOrderDetailsAdminCenterLoading {
  background-color: dimgrey;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  margin: 10px;
  max-width: 150px;
  width: 150px;
}

.viewOrderDetailsAdminCenterLoading:hover {
  background-color: dimgrey;
  transition: all 0.3s;
}

.shippedText {
  color: green;
  height: 100%;
  padding: 5px 10px;
  font-size: 18px;
  font-weight: 800;
  margin: 0px;
}

.shippedCircle {
  background-color: green;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.loadingBars {
  width: 100%;
  height: 30px;
  background-color: lightgrey;
  border-radius: 5px;
  animation: Animation 5s infinite;
  animation-direction: alternate;
  background: linear-gradient(rgb(150, 150, 150), rgb(174, 174, 174));
  background-size: 200% 200%;
}

.loadingButton {
  color: white;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  text-decoration: none;
  width: 75px;
  height: 30px;
  -webkit-animation: Animation 5s ease infinite;
  -moz-animation: Animation 5s ease infinite;
  animation: Animation 5s ease infinite;
  animation-direction: alternate;
  background: linear-gradient(rgb(150, 150, 150), rgb(174, 174, 174));
  background-size: 200% 200%;
}

@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

.companyDetailsSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-flow: row wrap;
  align-items: center;
}

.trainingRow {
  width: 50%;
}

.trainingButton {
  margin-top: 30px;
}

meter::-webkit-meter-optimum-value {
  background: #d2282e;
}
meter::-moz-meter-bar {
  /* Firefox Pseudo Class */
  background: #d2282e;
}

.filtersBar {
  flex-direction: row;
  overflow-x: scroll;
}

.filter {
  width: 100% !important;
  max-width: 250px;
}

.filterButton {
  width: 100% !important;
  max-width: 150px;
}

::-webkit-scrollbar {
  height: 5px;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: #9f9f9f;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.dashboardNavigation {
  display: block;
  width: 10vw;
  max-width: 300px;
  min-width: 200px;
}

.dashboardAccountsListItem {
  background-color: white;
}

.dashboardAccountsListItem:hover {
  background-color: lightgrey;
}

@media only screen and (max-width: 975px) {
  .fullHeader {
    display: block;
  }

  .column {
    float: left;
    width: 100% !important;
  }

  .trainingRow {
    width: 100% !important;
  }

  .trainingButton {
    width: 100% !important;
  }

  .filtersBar {
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    background-color: rgb(215, 215, 215) !important;
    padding: 10px !important;
    border-radius: 5px;
    box-shadow: 0px 0px 8px black;
    margin: 10px;
  }

  .filter {
    width: 100% !important;
  }

  .filterButton {
    width: 100% !important;
    max-width: 100% !important;
  }

  .dashboardNavigation {
    display: none !important;
    max-width: 100px !important;
  }
}
